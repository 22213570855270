import React from "react";
import Lottie from "react-lottie";
// import animationData from "../../Assets/lottie/animationData.json";
import animationData from "../../Assets/lottie/bot-animation-hello.json";

const GetStarted = ({ setSelectedIndex }) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="flex flex-col items-center justify-between h-[80vh] w-[30vw] lg:lg-small:w-[35vw] md:md-max:w-[50vw] md-small:!w-[100vw] md-small:h-[100dvh] gap-5 pb-10">
      <div className="flex bg-custom-gradient justify-center items-center overflow-visible h-1/2 w-[50%] border-t-0 [&>div]:!overflow-visible [&>div>svg]:!overflow-visible">
        <Lottie options={defaultOptions} height={500} width={500} />
      </div>
      <div className="flex flex-col items-center gap-4 w-[90%]">
        <h3 className="text-[#3573F9] text-4xl font-semibold">Hi, I am Aly!</h3>
        <p className="max-w-[90%] text-center text-lg">Hello, I'm Aly, It's nice to meet you. I'm here to assist with any questions or information about myaptitude.ai career test.</p>
      </div>
      <div className="flex justify-center w-full">
        <button onClick={() => setSelectedIndex(1)} className="w-[90%] text-base bg-[#3573F9] rounded-full text-white py-2.5">Get Started</button>
      </div>
    </div>
  );
};

export default GetStarted;
