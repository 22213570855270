import React, { useState } from "react";
import { Rating } from "primereact/rating";
import { Dialog } from "primereact/dialog";
import { useRecoilState } from "recoil";
import { isReviewSubmitted } from "../../utils/recoil";
import Lottie from "react-lottie";
import animationData from "../../Assets/lottie/new-animation.json";

const UserReview = ({ setVisible, visible }) => {
  const [value, setValue] = useState(null);
  const [isSubmitted, setIsSubmitted] = useRecoilState(isReviewSubmitted);

  const handleChange = (e) => {
    setValue(e.value);
  };

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      {!isSubmitted && (
        // <Dialog
        //   visible={visible}
        //   onHide={() => setVisible(false)}
        //   className="sm-max:w-[90vw] overflow-y-hidden"
        // >
        //   <div className="bg-white  rounded-[20px] flex flex-col justify-center items-center py-[7rem] gap-7 sm-max:py-[3rem] sm-max:px-3 sm-max:gap-4">
        //     <p className="text-[#333333] text-2xl font-bold text-center sm-max:text-xl">
        //       Rate Your Experience
        //     </p>
        //     <p className="text-[#666666] w-[60%] text-center text-xl sm-max:w-full sm-max:text-lg">
        //       Your feedback matters! Please take a moment to rate your
        //       experience
        //     </p>

        //     <Rating value={value} onChange={handleChange} cancel={false} />
        //     <button
        //       type="button"
        //       className="bg-[#1C75BC] text-white py-3 px-14 font-2xl rounded-xl font-semibold"
        //       onClick={() => [setVisible(false), setIsSubmitted(true)]}
        //     >
        //       Submit
        //     </button>
        //   </div>
        // </Dialog>

        <Dialog
          visible={visible}
          onHide={() => setVisible(false)}
          className="sm-max:w-[90vw] overflow-y-hidden [&>div]:p-0 [&>div]:bg-custom-gradient [&>*:first-child]:!bg-[#3573F9]"
        >
          <div className="flex flex-col justify-center items-center">
            <div className=" [&>div>svg]:!w-[200px] [&>div>svg]:!h-[400px] [&>div>svg]:!-mt-[1.5rem] [&>div]:!overflow-visible [&>div>svg]:!-translate-x-[30%]">
              <Lottie options={defaultOptions} height={200} width={100} />
            </div>
            <div className="bg-white flex flex-col justify-center items-center gap-5 p-7 pt-[10rem]">
              <p className="text-[#333333] text-xl font-bold text-center sm-max:text-xl">
                Rate Your Experience
              </p>
              <p className="text-[#666666] w-[60%] text-center text-xl sm-max:w-full sm-max:text-lg">
                Your feedback matters! Please take a moment to rate your
                experience
              </p>

              <Rating value={value} onChange={handleChange} cancel={false} />
              <button
                type="button"
                className="bg-[#1C75BC] text-white py-3 px-14 font-2xl rounded-xl font-semibold"
                onClick={() => [setVisible(false), setIsSubmitted(true)]}
              >
                Submit
              </button>

            </div>
          </div>
        </Dialog>
      )}
    </>
  );
};

export default UserReview;
