import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../Layout/Layout";
import config from "../../config.json";
import Cookies from "js-cookie";
import { LoadingPage } from "../LoadingPage/LoadingPage";
import LanguageSelection from "../QuizInstruction/LanguageSelection";
import { languageSelectionModal } from "../../utils/recoil";
import { useRecoilState } from "recoil";
import Lottie from "react-lottie";
import animationData from "../../Assets/lottie/new-animation.json";
import Typewriter from "typewriter-effect";

const QuizInstruction = () => {
  const navigate = useNavigate();
  const tokenCookie = Cookies.get('token', { domain: config.cookieDomain });
  const isPaidCookie = Cookies.get('role', { domain: config.cookieDomain });
  const isTestTaken = Cookies.get('isTestAttended', { domain: config.cookieDomain });
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useRecoilState(languageSelectionModal);
  const [instructionIndex, setInstructionIndex] = useState(null);
  const [scale, setScale] = useState(0);

  useEffect(() => {
    if (tokenCookie) {
      if (isPaidCookie && (isPaidCookie === 'ROLE_UNPAID_USER')) navigate('/home');
      if (isTestTaken && (isTestTaken === 'TRUE')) navigate('/my_profile');
      setLoading(false);
    } else {
      navigate('/login');
    }
  })

  useEffect(() => {
    async function Scale() {
      const timer = setTimeout(() => {
        setScale(1.5);
      }, 100);
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setInstructionIndex(0);
      return () => clearTimeout(timer);
    }

    Scale();
  }, []);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return loading ? (
    <LoadingPage />
  ) : (
    <Layout>
      <LanguageSelection />
      <div className="bg-[#356dea10] rounded-2xl px-[4rem] mx-[4rem] mt-[2rem] py-[1rem] flex gap-5 justify-between text-xl sm-max:text-base sm-max:gap-2 md-max:mx-[2rem] md-max:px-[2rem] sm-max:mx-[1rem] sm-max:px-[1rem] sm-max:py-[1rem] relative overflow-hidden min-h-[90vh] md-max:h-full">
        <div className="flex flex-col gap-[1vh] w-full md-max:items-center">
          <p className="text-[4vh] leading-[7vh] bg-gradient-to-r from-[#1C36BC] to-[#1C75BC] bg-clip-text text-transparent font-semibold sm-max:text-center w-[100vw]">
            Welcome to Our Career Assessment Test
          </p>
          <div className="flex justify-between md-max:flex-col-reverse">
            <div className="max-w-[65%] md-max:max-w-full md-max:items-center">
              <div className=" flex flex-col gap-3 sm-max:gap-1">
                <p className="text-[2vh]">
                  Before you start, here are some quick instructions:
                </p>

                <div className="flex flex-col gap-[1vh]">
                  {instructionIndex !== null && (
                    <div className="flex gap-[1vh]">
                      <div className="size-10 bg-white rounded-full flex justify-center items-center">
                        <Lottie
                          options={defaultOptions}
                          height={60}
                          width={60}
                        />
                      </div>

                      <div className="bg-white rounded-b-2xl rounded-tr-2xl px-6 py-3 text-[2vh]">
                        <Typewriter
                          options={{
                            strings: [
                              "<strong>Find a Quiet Spot:</strong> Choose a quiet place where you can focus without interruptions.",
                            ],
                            delay: 20,
                            cursor: " ",
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .typeString(
                                "<strong>Find a Quiet Spot:</strong> Choose a quiet place where you can focus without interruptions."
                              )
                              .start()
                              .pauseFor(1000)
                              .callFunction(() => {
                                console.log("String typed out!");
                                setInstructionIndex(1);
                              });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {instructionIndex >= 1 && (
                    <div className="flex gap-[1vh]">
                      <div className="size-10 bg-white rounded-full flex justify-center items-center">
                        <Lottie
                          options={defaultOptions}
                          height={60}
                          width={60}
                        />
                      </div>

                      <div className="bg-white rounded-b-2xl rounded-tr-2xl px-6 py-3 text-[2vh]">
                        <Typewriter
                          options={{
                            delay: 20,
                            cursor: " ",
                          }}
                          onInit={(typewriter) => {
                            console.log("hi");
                            console.log(typewriter);
                            typewriter
                              .typeString(
                                "<strong>Be Honest:</strong> There are no right or wrong answers. Just be yourself!"
                              )
                              .start()
                              .callFunction(() => {
                                console.log("Second string typed out!");
                                setInstructionIndex(2);
                              });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {instructionIndex >= 2 && (
                    <div className="flex gap-[1vh]">
                      <div className="size-10 bg-white rounded-full flex justify-center items-center">
                        <Lottie
                          options={defaultOptions}
                          height={60}
                          width={60}
                        />
                      </div>

                      <div className="bg-white rounded-b-2xl rounded-tr-2xl px-6 py-3 text-[2vh]">
                        <Typewriter
                          options={{
                            delay: 20,
                            cursor: " ",
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .typeString(
                                "<strong>Relax:</strong> Don't stress! Just go with your gut feeling."
                              )
                              .start()
                              .callFunction(() => {
                                setInstructionIndex(3);
                              });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {instructionIndex >= 3 && (
                    <div className="flex gap-[1vh]">
                      <div className="size-10 bg-white rounded-full flex justify-center items-center">
                        <Lottie
                          options={defaultOptions}
                          height={60}
                          width={60}
                        />
                      </div>

                      <div className="bg-white rounded-b-2xl rounded-tr-2xl px-6 py-3 text-[2vh]">
                        <Typewriter
                          options={{
                            delay: 20,
                            cursor: " ",
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .typeString(
                                "<strong>Think long-term:</strong> Consider your future goals when answering."
                              )
                              .start()
                              .callFunction(() => {
                                setInstructionIndex(4);
                              });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {instructionIndex >= 4 && (
                    <div className="flex gap-[1vh]">
                      <div className="size-10 bg-white rounded-full flex justify-center items-center">
                        <Lottie
                          options={defaultOptions}
                          height={60}
                          width={60}
                        />
                      </div>

                      <div className="bg-white rounded-b-2xl rounded-tr-2xl px-6 py-3 text-[2vh]">
                        <Typewriter
                          options={{
                            delay: 20,
                            cursor: " ",
                          }}
                          onCharacterTyped={(char) => {
                            console.log("hi");
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .typeString(
                                "<strong>Take your Time:</strong> No rush, but try to keep a steady pace."
                              )
                              .start()
                              .callFunction(() => {
                                setInstructionIndex(5);
                              });
                          }}
                        />
                      </div>
                    </div>
                  )}

                  {instructionIndex >= 5 && (
                    <div className="flex gap-[1vh]">
                      <div className="size-10 bg-white rounded-full flex justify-center items-center">
                        <Lottie
                          options={defaultOptions}
                          height={60}
                          width={60}
                        />
                      </div>

                      <div className="bg-white rounded-b-2xl rounded-tr-2xl px-6 py-3 text-[2vh]">
                        <Typewriter
                          options={{
                            delay: 20,
                            cursor: " ",
                          }}
                          onInit={(typewriter) => {
                            typewriter
                              .typeString(
                                "<strong>Review Your Answers:</strong> Double-check before you finish."
                              )
                              .start()
                              .callFunction(() => {
                                setInstructionIndex(6);
                              });
                          }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {instructionIndex >= 6 && (
                <div className="text-[2vh] mt-[2vh]">
                  <Typewriter
                    options={{
                      delay: 20,
                      cursor: " ",
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString(
                          `<span style="color: blue; text-decoration: underline;"><a href="mailto:info@uslotedu.com">Need Help?</a></span> If you're stuck or have questions, we're here to help.`
                        )
                        .start()
                    }}
                  />

                  <Typewriter
                    options={{
                      delay: 20,
                      cursor: " ",
                    }}
                    onInit={(typewriter) => {
                      typewriter
                        .typeString(
                          'Ready? Let\'s get started! Click <span class="font-semibold">"Begin"</span> below to begin the assessment.'
                        )
                        .start()
                        .callFunction(() => {
                          document.querySelector(
                            ".begin-button"
                          ).style.display = "block";
                        });
                    }}
                  />
                  <div className="flex justify-start md-max:justify-center mt-[1.5vh]">
                    <button
                      onClick={() => setVisible(true)}
                      className="bg-[#1C75BC] text-white rounded-lg py-[1.5vh] px-[2rem] text-[2vh] font-semibold text-xl begin-button"
                      style={{ display: "none" }}
                    >
                      Begin
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div
              className="overflow-visible transition-transform duration-1000 ease-in-out h-[80vh]"
              style={{
                transform: `scale(${window.innerWidth <= 1280 ? Math.min(scale, 1) :
                  // 1024 <= window.innerWidth <= 1280 ? Math.min(scale, 1.1) :
                  scale
                  })`
              }}
            >
              <Lottie options={defaultOptions} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default QuizInstruction;
