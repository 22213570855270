import React from "react";
import { Radar, RadarChart, PolarGrid, PolarAngleAxis } from "recharts";
import { PolarAngleAxisData } from "../../utils/data";


function sortByStartingLetter(a = '', b = '', startingLetters) {
  const aStart = startingLetters.indexOf(a.charAt(0).toLowerCase());
  const bStart = startingLetters.indexOf(b.charAt(0).toLowerCase());
  return aStart - bStart;
}

const startingLetters = ['i', 'r', 'a', 'c', 'e', 's'];

const ResultChart = ({ item }) => {
  let newArray = [];
  Object.keys(item?.prediction?.holland?.values)
    .sort((a, b) => sortByStartingLetter(a, b, startingLetters))
    .map((key) => {
      newArray.push({
        type: key,
        value: parseFloat(item?.prediction?.holland?.values[key].replace("%", "")),
        total: 100,
      });
    });
  return (
    <div className="relative">
      <RadarChart
        cx={300}
        cy={250}
        outerRadius={150}
        width={560}
        height={440}
        data={newArray}
        className="sm-max:!w-[100%] sm-max:!h-[50%]"
      >
        <PolarGrid />
        {PolarAngleAxisData.map((item) => (
          <g class="recharts-layer recharts-polar-angle-axis-tick">
            <line
              class="angleAxis"
              cx="300"
              cy="250"
              orientation="outer"
              radius="150"
              fill="none"
              x1={item.line_x1}
              y1={item.line_y1}
              x2={item.line_x2}
              y2={item.line_y2}
            ></line>
            <text
              cx="300"
              cy="250"
              orientation="outer"
              radius="150"
              stroke="none"
              x={item.text_x}
              y={item.text_y}
              class="recharts-text recharts-polar-angle-axis-tick-value"
              text-anchor={item.text_anchor}
              fill="black"
            >
              <tspan x={item.tspan_x} dy="0em">
                {item.axis_name}
              </tspan>
            </text>
          </g>
        ))}
        <Radar
          dataKey="value"
          stroke="#2AAF59B2"
          fill="#2AAF59B2"
          fillOpacity={0.6}
        />
        {newArray.map((entry, index) => {
          const labelWidth = entry.type.length * 0;
          const x =
            Math.cos(-Math.PI / 2 - (index * 2 * Math.PI) / newArray.length) *
            -200 +
            300 -
            labelWidth / 3;
          const labelHeight = 20;
          const y =
            Math.sin(-Math.PI / 2 - (index * 2 * Math.PI) / newArray.length) *
            175 +
            260 -
            labelHeight;
          return (
            <text
              key={`text-${index}`}
              x={x}
              y={y}
              textAnchor="middle"
              dominantBaseline="bottom"
              fill="#2AAF59"
              fontWeight="bold"
            >
              {`${entry.value}%`}
            </text>
          );
        })}
      </RadarChart>
    </div>
  );
};

export default ResultChart;
