import React from "react";
import { RiInstagramLine } from "react-icons/ri";
import mail_icon from "../../Assets/mail.png";
import twiter_icon from "../../Assets/twiter.png";
import result_character from "../../Assets/result_character.png";
const YourResultSection = ({ item }) => {
  const getValues = (data1, data2) => {
    let colorClass, justify, largerPercentage;
    largerPercentage = data1 > data2 ? data1 : data2;
    if (largerPercentage === data1) {
      justify = "justify-start";
      colorClass = "bg-green-500";
    } else if (largerPercentage === data2) {
      justify = "justify-end";
      colorClass = "bg-green-500";
    }
    return { colorClass, justify, largerPercentage };
  };

  const transformedData = [
    [
      { key: "Extroverted", value: item?.prediction?.mbti.values.EXTROVERT },
      { key: "Introverted", value: item?.prediction?.mbti.values.INTROVERT },
    ],
    [
      { key: "Intuition", value: item?.prediction?.mbti.values.INTUITION },
      { key: "Sensing", value: item?.prediction?.mbti.values.SENSING },
    ],
    [
      { key: "Thinking", value: item?.prediction?.mbti.values.THINKING },
      { key: "Feeling", value: item?.prediction?.mbti.values.FEELING },
    ],
    [
      { key: "Prospecting", value: item?.prediction?.mbti.values.PROSPECTING },
      { key: "Judging", value: item?.prediction?.mbti.values.JUDGING },
    ],
  ];
  return (
    <div className="w-full">
      <div className="bg-[#EDEDED] px-4 py-11 mt-12 flex flex-col gap-8 rounded-2xl md-max:hidden sm-max:hidden w-full">
        <p className="text-xl font-semibold text-center">Your Results</p>
        <div className="  flex justify-center ">
          <img
            src={result_character}
            alt="result Character"
            className="object-cover w-[7.125rem] h-[7.125rem] rounded-full"
          />
        </div>
        <div className="flex flex-col gap-3">
          <p className="text-xl  text-[#3976f9] font-semibold  text-center">
            {item?.name}
          </p>
          {/* <p className="text-xl text-center ">
            {item?.prediction?.mbti.entity.code}-
            {item?.prediction?.holland.entity.code}
          </p> */}
        </div>
        <div>
          {transformedData?.map((item) => (
            <>
              <div
                className={`w-full flex ${getValues(item[0].value, item[1].value).justify
                  } bg-[#D9D9D9] rounded-full`}
              >
                <div
                  className={`rounded-full h-[10px] ${getValues(item[0].value, item[1].value).colorClass
                    }`}
                  style={{
                    width: `${getValues(item[0].value, item[1].value).largerPercentage
                      }`,
                  }}
                ></div>
              </div>

              <div className="flex justify-between mb-8">
                <div>
                  <p>{parseInt(item[0].value?.split('.')[0])}%</p>
                  <p>{item[0].key}</p>
                </div>
                <div>
                  <p className="text-right">{parseInt(item[1].value?.split('.')[0])}%</p>
                  <p>{item[1].key}</p>
                </div>
              </div>
            </>
          ))}
          <div className="flex flex-col gap-4 items-center justify-center border-t-2 pt-6">
            <p>Share Your Results</p>
            <div className="flex items-center gap-4">
              <img src={mail_icon} alt="icon" className="w-[25px] h-[20px]" />
              <img src={twiter_icon} alt="icon" className="w-[20px] h-[20px]" />
              <RiInstagramLine color="#1C75BC" className="w-6 h-6" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default YourResultSection;
