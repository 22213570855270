import React, { useEffect, useRef, useState } from "react";
import Lottie from "react-lottie";
// import animationData from "../../Assets/lottie/animationData.json";
import animationData from "../../Assets/lottie/bot-animation-hello.json";
import { Tab } from "@headlessui/react";
import GetStarted from "./GetStarted";
import ChatBox from "./ChatBox";

const ChatBot = ({ showModal, setShowModal }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="fixed bottom-5 right-5 md-small:right-0 md-small:bottom-0 cursor-pointer z-40 group">
      <div className="relative flex flex-col items-end max-w-[30vw] ">
        <div
          className={`${
            showModal ? "block" : "hidden"
          } min-h-full h-[80vh] md-small:h-[100dvh] rounded-3xl bg-white absolute bottom-5 md-small:bottom-0 !z-[200]`}
        >
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="h-0">
              <Tab></Tab>
              <Tab></Tab>
            </Tab.List>
            <Tab.Panels>
              <Tab.Panel>
                <GetStarted setSelectedIndex={setSelectedIndex} />
              </Tab.Panel>
              <Tab.Panel>
                <ChatBox setShowModal={setShowModal} showModal={showModal} />
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>

        <div
          onClick={() => setShowModal((prevState) => !prevState)}
          className="relative group [&>div]:!z-[100] [&>div]:relative [&>div]:bottom-0 [&>div]:right-0"
        >
          <Lottie options={defaultOptions} height={150} width={150} />
          <section
            className={`w-0 px-0 max-h-[80px] flex group-hover:w-[30ch] group-hover:px-5 flex-col gap-2 fixed bottom-[6%] right-[50px] bg-[#F1F7FF] py-2.5 rounded-xl !z-30 transition-all duration-300 overflow-hidden`}
          >
            <h5 className="font-bold overflow-x-hidden truncate">
              Hello, I am Aly!
            </h5>
            <p className="overflow-x-hidden truncate">
              How may i help you today?
            </p>
          </section>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;
