import React from "react";
import "./Footer.css";
import isolationlogo from "../../Assets/my-aptitude-ai-uslot.png";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-main-content">
        <div className="footer-content-1">
          <Link to={"/"}>
            <img src={isolationlogo} alt="logo" className="object-cover h-[5rem]" />
          </Link>
          <p>
            Uslot AI Ed tech is an AI-driven platform offering personalized
            career guidance based on aptitude and personality assessments.
          </p>
        </div>
        <div className="footer-content-2">
          <h1>QUICK LINKS</h1>
          <Link to={"/privacy_policy"}>
            <h2>Privacy and Policy </h2>
          </Link>
          <Link to={"/terms_conditon"}>
            <h2> Terms and Condition </h2>
          </Link>
          <Link to={"/cancellation_refund_policy"}>

            <h2>Cancellation and Refund </h2>
          </Link>
          {/* <Link to={"/shipping_delivery_policy"}>

            <h2> shipping and delivery</h2>
</Link> */}
        </div>
        <div className="footer-content-2">
          <h1>Contact us</h1>

          <h2><a href="mailto:info@uslotedu.com">info@uslotedu.com</a></h2>

          <h2> +91 8086 480 470 </h2>
          <h2> CBI Road, Katrikadvu, Kaloor,<br/> Ernakulam, Kerala </h2>
        </div>
      </div>
      <div className="footer-line"></div>
    </div>
  );
};

export default Footer;
