import React, { useEffect, useRef, useState } from "react";
import botImage from "../../Assets/bot-image.svg";
import onlineBadge from "../../Assets/online-badge.svg";
import dropIcon from "../../Assets/chatbot-drop-icon.svg";
import Lottie from "react-lottie";
import animationData from "../../Assets/lottie/new-animation.json";
import ChatBotInputDialog from "./ChatBotInputDialog";
import Cookies from "js-cookie";
import config from "../../config.json";
import { chatbotFAQ } from "../../utils/data";
import { Link } from "react-router-dom";
import arrowIcon from '../../Assets/arrow-right.svg';

const ChatBox = ({ setShowModal, showModal }) => {
  // const [showMessage, setShowMessage] = useState(false);
  // const [showInput, setShowInput] = useState(false);
  const [showFAQ, setShowFAQ] = useState(true);
  const [message, setMessage] = useState([]);
  const [filteredFAQs, setFilteredFAQs] = useState([]);

  const messageRef = useRef();

  const token = Cookies.get("token", { domain: config.cookieDomain });

  console.log(token, "token");

  // useEffect(() => {
  //   setShowMessage(false);
  //   const timer = setTimeout(() => {
  //     setShowMessage(true);
  //   }, 3000); // 3 seconds delay

  //   return () => clearTimeout(timer); // Cleanup the timer on component unmount
  // }, [showModal]);

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleFAQ = (index) => {
    setShowFAQ(false);
    const newMessage = chatbotFAQ?.find((item) => item?.id === index);
    setMessage((prevState) => [...prevState, newMessage]);
  };

  console.log(message);

  useEffect(() => {
    const newFAQs = chatbotFAQ?.filter(
      (itemA) => !message?.some((itemB) => itemA.id === itemB.id)
    );

    setFilteredFAQs(newFAQs);

    console.log(newFAQs);
  }, [message]);

  const handleRaiseTicket = () => {
    setShowFAQ(false);
    const raiseTicketObject = {
      id: 999,
      text: "Raise a Ticket",
      answer: "Sign In to Continue",
    };

    setMessage((prevState) => [...prevState, raiseTicketObject]);
  };

  useEffect(() => {
    messageRef.current.scrollTo({
      top: messageRef.current.scrollHeight,
      behavior: "smooth",
    });
  }, [message, filteredFAQs]);

  return (
    <div className="flex flex-col h-[80vh] w-[30vw] lg:lg-small:w-[35vw] md:md-max:w-[50vw] md-small:!w-[100vw] md-small:h-[100dvh] justify-between pb-10 ">
      <div className="flex flex-col items-center gap-5 w-full h-[90%]">
        <div className="flex justify-between items-center bg-[#3573F9] w-full py-8 px-5 rounded-t-3xl md-small:rounded-t-none">
          <div className="flex gap-4 items-center">
            <div className="bg-[#EEF2FF] rounded-full w-11 h-11 flex justify-center items-center">
              <img src={botImage} alt="Bot" />
            </div>
            <div className="flex flex-col text-white ">
              <h4 className="text-xl">Aly</h4>
              <div className="flex gap-1 items-center">
                <img src={onlineBadge} alt="Online Badge" />
                <p className="text-base text-[#E9E9E9]">Online</p>
              </div>
            </div>
          </div>

          <img
            src={dropIcon}
            alt="Arrow Down"
            className="cursor-pointer"
            onClick={() => setShowModal(false)}
          />
        </div>

        {showFAQ && (
          <div className="flex flex-col gap-4 px-5 justify-center w-full overflow-y-scroll pointer-events-auto">
            <div className="flex gap-4 items-start pb-3">
              <div className="flex justify-center bg-[#F1F7FF] rounded-full items-center size-11 shadow-sm [&>div>svg]:!translate-x-[-5%]">
                <Lottie options={defaultOptions} height={60} width={60} />
              </div>
              <div className="flex flex-col">
                <p className="text-[#3573F9] font-semibold text-xl">Aly</p>
                <div className="bg-[#F1F7FF] rounded-b-lg rounded-tr-lg shadow-md">
                  <p className="py-2 px-4">Do you have any questions?</p>
                </div>
              </div>
            </div>

            <div className="flex flex-col gap-4 px-5 items-center w-full pointer-events-auto">
              {chatbotFAQ?.map((data) => (
                <div
                  key={data?.id}
                  className="bg-[#F1F7FF] text-[#28282850] hover:text-[#282828] px-5 py-3 shadow-sm rounded-b-lg rounded-tr-lg w-[75%] text-center "
                  onClick={() => handleFAQ(data?.id)}
                >
                  <p>{data?.text}</p>
                </div>
              ))}
            </div>

            <div ref={messageRef} />
          </div>
        )}

        {!showFAQ && (
          <div
            ref={messageRef}
            className="flex flex-col gap-5 px-5 pb-5 w-full h-full !overflow-y-scroll"
            style={{ pointerEvents: "unset !important" }}
          >
            <div className="flex gap-4 items-start pb-3">
              <div className="flex justify-center bg-[#F1F7FF] rounded-full items-center size-11 shadow-sm [&>div>svg]:!translate-x-[-5%]">
                <Lottie options={defaultOptions} height={60} width={60} />
              </div>
              <div className="flex flex-col">
                <p className="text-[#3573F9] font-semibold text-xl">Aly</p>
                <div className="bg-[#F1F7FF] rounded-b-lg rounded-tr-lg shadow-md">
                  <p className="py-2 px-4">Do you have any questions?</p>
                </div>
              </div>
            </div>

            {message?.map((item) => (
              <>
                <div className="bg-[#3573F9] text-[#fafafa] px-5 py-3 shadow-sm rounded-b-lg rounded-tl-lg self-end  w-[80%]">
                  <p>{item?.text}</p>
                </div>
                <div className="flex gap-4 items-start">
                  <div className="flex justify-center bg-[#F1F7FF] rounded-full items-center size-11 shadow-sm [&>div>svg]:!translate-x-[-5%]">
                    <Lottie options={defaultOptions} height={60} width={60} />
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#3573F9] font-semibold text-xl">
                      Aly
                    </p>
                    <div className="bg-[#F1F7FF] rounded-b-lg rounded-tr-lg shadow-md flex flex-col items-center">
                      <p className="py-2 px-4">{item?.answer}</p>
                      {item?.id === 999 && (
                        <Link to="/login">
                          <button
                            className="bg-[#3573F9] w-max rounded-full mb-3 text-white py-2 px-6 mx-3 flex items-center gap-3"
                            onClick={() => setShowModal(false)}
                          >
                            <p>Sign In</p>
                            <img src={arrowIcon} alt="Arrow" width={17} />
                          </button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </>
            ))}

            <div className="flex flex-col gap-4 px-5 items-center w-full pointer-events-auto">
              {filteredFAQs?.map((data) => (
                <div
                  key={data?.id}
                  className="bg-[#F1F7FF] text-[#28282850] hover:text-[#282828] px-5 py-3 shadow-sm rounded-b-lg rounded-tr-lg w-[75%] text-center "
                  onClick={() => handleFAQ(data?.id)}
                >
                  <p>{data?.text}</p>
                </div>
              ))}
            </div>

            <div ref={messageRef} />
          </div>
        )}

        {/* {showMessage && (
          <div className="w-[90%] pt-2">
            <div className="flex gap-4 items-center">
              <div className="flex justify-center bg-[#F1F7FF] rounded-full items-center size-11 shadow-sm [&>div>svg]:!translate-x-[-5%]">
                <Lottie options={defaultOptions} height={60} width={60} />
              </div>
              <div className="bg-[#F1F7FF] rounded-b-lg rounded-tr-lg shadow-md">
                <p className="py-2 px-4">i am still here! Do you have any questions?</p>
              </div>
            </div>
          </div>
        )} */}
      </div>
      {token ? (
        <ChatBotInputDialog setMessage={setMessage} />
      ) : (
        <div className={`flex justify-center`}>
          <button
            className="bg-[#3573fa] text-white w-[90%] rounded-full py-3"
            onClick={handleRaiseTicket}
          >
            Raise a Ticket
          </button>
        </div>
      )}
    </div>
  );
};

export default ChatBox;
