import { useSprings, animated, to as interpolate } from "@react-spring/web";
import React, { useState } from "react";
import { useDrag } from "react-use-gesture";
import { reviewsData } from "../../utils/data";

const to = (i) => ({
  x: 0,
  rot: -10 + Math.random() * 20,
  delay: i * 100,
});
const from = (_i) => ({ x: 0, rot: 0, scale: 1.5 });
// This is being used down there in the view, it interpolates rotation and scale into a css transform
const trans = (r, s) => `rotateX(30deg) rotateZ(${r}deg) scale(${s})`;

const Cards = () => {
  const [gone] = useState(() => new Set()); // The set flags all the cards that are flicked out
  const [props, api] = useSprings(reviewsData.length, (i) => ({
    ...to(i),
    from: from(i),
  })); // Create a bunch of springs using the helpers above
  // Create a gesture, we're interested in down-state, delta (current-pos - click-pos), direction and velocity
  const bind = useDrag(
    ({ args: [index], down, movement: [mx], direction: [xDir], velocity }) => {
      const trigger = velocity > 0.2; // If you flick hard enough it should trigger the card to fly out
      const dir = xDir < 0 ? -1 : 1; // Direction should either point left or right
      if (!down && trigger) gone.add(index); // If button/finger's up and trigger velocity is reached, we flag the card ready to fly out
      api.start((i) => {
        if (index !== i) return; // We're only interested in changing spring-data for the current spring
        const isGone = gone.has(index);
        const x = isGone ? (200 + window.innerWidth) * dir : down ? mx : 0; // When a card is gone it flys out left or right, otherwise goes back to zero
        const rot = mx / 100 + (isGone ? dir * 10 * velocity : 0); // How much the card tilts, flicking it harder makes it rotate faster
        const scale = down ? 1.75 : 1.75; // Active cards lift up a bit
        return {
          x,
          rot,
          scale,
          delay: undefined,
          config: { friction: 50, tension: down ? 800 : isGone ? 200 : 500 },
        };
      });
      if (!down && gone.size === reviewsData.length)
        setTimeout(() => {
          gone.clear();
          api.start((i) => to(i));
        }, 600);
    }
  );

  return (
    <div>
      {props.map(({ x, y, rot, scale }, i) => (
        // {reviewsData?.map((review) => (
        <animated.div
          key={i}
          style={{ x, y }}
          className="absolute w-[75%] max-w-[90%] flex justify-center items-center h-[500px]"
        >
          <animated.div
            {...bind(i)}
            style={{
              transform: interpolate([rot, scale], trans),
            }}
          >
            <div className="rounded-xl border-2 border-[#DFE1E6] bg-white px-6 py-10 flex flex-col justify-between gap-5 w-[40vw]">
              <p>"{reviewsData[i]?.review}"</p>
              <div className="flex flex-col gap-2">
                <hr />
                <div className="flex items-center gap-4">
                  <img
                    src={reviewsData[i]?.image}
                    alt={reviewsData[i]?.alt}
                    className="rounded-full size-14"
                  />
                  <div className="flex flex-col">
                    <p className="text-base font-medium">
                      {reviewsData[i]?.name}
                    </p>
                    <p className="text-sm text-[#666D80]">
                      {reviewsData[i]?.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </animated.div>
        </animated.div>
      ))}
      {/* ))} */}
    </div>
  );
};

export default Cards;
