import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import Cookies from "js-cookie";
import config from "../../config.json";
import { useRecoilState, useSetRecoilState } from "recoil";
import { couponCode, courseAmount, redeemedAmount } from "../../utils/recoil";
import { useNavigate } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";


const ApplyCode = ({ toast }) => {
  const [visible, setVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [isValid, setIsValid] = useState("");
  const [text, setText] = useState('');
  const [_courseAmount, setCourseAmount] = useRecoilState(courseAmount);
  const [_redeemedAmount, setRedeemedAmount] = useRecoilState(redeemedAmount);
  const navigate = useNavigate();
  const isPaidCookie = Cookies.get("role", { domain: config.cookieDomain });
  const setCouponCode = useSetRecoilState(couponCode);
  const [loading, setLoading] = useState(false);

  const submitCode = () => {
    if (!loading) {
      if (inputValue.length) {
        setLoading(true)
        fetch(config.domain + "/verify-coupon/" + inputValue, {
          credentials: "include",
        })
          .then(res => res.json())
          .then(result => {
            console.log(result);
            if (result.statusCode === 200) {
              setIsValid("true");
              setText("Coupon Applied Successfully");
              setRedeemedAmount((_courseAmount - result.data.discountedAmount) < 1 ? 1 : (_courseAmount - result.data.discountedAmount));
              setCouponCode(inputValue)
              setVisible(false);
              setLoading(false)
            } else {
              setIsValid("false");
              setText(result.error.msg === 'COUPON_EXPIRED' ? 'Copoun Expired' : 'Invalid Coupon');
              setLoading(false)
            }
          })
          .catch(err => {
            console.log(err);
            toast.current.show({
              severity: "error",
              summary: "Coupon verification Failed",
              life: 3000,
            });
          })
      }
    }
  }

  const openModel = () => {
    const cookieValue = Cookies.get("token", { domain: config.cookieDomain });
    if (cookieValue) {
      setVisible(true)
    } else {
      navigate("/login");
    }
  }

  return (
    isPaidCookie === 'ROLE_UNPAID_USER' ?
      <div
        className="applyCode card flex justify-content-center"
      >
        <p onClick={() => openModel()} className="text-[#1c75bc] underline cursor-pointer">Apply Code</p>
        <Dialog
          visible={visible}
          onHide={() => setVisible(false)}
          style={{ width: "30vw" }}
          breakpoints={{ "960px": "75vw", "641px": "90vw" }}
          className=" applyCode p-fluid p-dialog-custom flex justify-center"
        >
          <div className="flex flex-col gap-5 ">

            {/* <div className=" flex flex-col justify-center items-center gap-1"> */}
            <div className="flex w-full items-center justify-center pb-10">
              <div className="flex relative" style={{ width: '80%' }}>
                <InputText
                  id="inputField"
                  value={inputValue}
                  keyfilter="alphanum"
                  placeholder="Have Coupon Code?"
                  onChange={(e) => [setInputValue(e.target.value), setIsValid(true), setText('')]}
                  className={`border-[2px] border-dashed rounded-l-lg h-10 border-r-0 shadow-none text-black ${isValid === "false" && 'border-red-400 text-red-500'}`}
                  style={{ width: "80%", padding: "0.5rem" }}
                />
                <div
                  onClick={submitCode}
                  className={`h-10 flex items-center justify-center text-sm px-4 rounded-r-lg ${inputValue?.length ? 'bg-green-500 text-white cursor-pointer' : 'text-gray-500 bg-gray-200 cursor-not-allowed'} ${isValid === "false" && 'border-red-400 border-2 border-dashed border-l-0 text-gray-500 bg-gray-200 cursor-not-allowed'}`}>
                  {loading ?
                    <ColorRing
                      visible={true}
                      height="40"
                      width="40"
                      ariaLabel="color-ring-loading"
                      wrapperStyle={{}}
                      wrapperClass="color-ring-wrapper"
                      colors={['#ffffff', '#ffffff', '#ffffff', '#ffffff', '#ffffff']}
                    /> :
                    'Apply'}
                </div>
                <p className={`absolute left-0 text-sm -bottom-6 ${isValid === "false" && 'text-red-500'} ${isValid === "true" && 'text-green-500'}`}>{text}</p>
              </div>

            </div>
          </div>

        </Dialog>
      </div>
      : <></>
  );
};

export default ApplyCode;
