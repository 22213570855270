import React, { useState } from "react";
import Quizhome from "./Components/Quiezhome/Quizhome";
import Layout from "./Components/Routing/Routingpage";
import Routingpage from "./Components/Routing/Routingpage";
import { PrimeReactProvider, PrimeReactContext } from "primereact/api";
import "primereact/resources/themes/saga-blue/theme.css"; // Theme
import "primereact/resources/primereact.min.css"; // Core CSS
import { RecoilRoot } from "recoil";
import ChatBot from "./Components/ChatBot/ChatBot";
import { BrowserRouter, useLocation } from "react-router-dom";

const App = () => {
  const [showModal, setShowModal] = useState(false);

  console.log(window.location.href);

  const path = window.location.href.split("/").pop();

  console.log(path);

  return (
    <BrowserRouter>
      <RecoilRoot>
        <PrimeReactProvider>
          <div onClick={() => setShowModal(false)}>
            <Routingpage />
          </div>
          {path !== "instruction" && (
            <ChatBot setShowModal={setShowModal} showModal={showModal} />
          )}
        </PrimeReactProvider>
      </RecoilRoot>
    </BrowserRouter>
  );
};

export default App;
