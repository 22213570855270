import React, { useState } from 'react'
import sendIcon from "../../Assets/send-arrow-up-icon.svg";
import config from "../../config.json";

const ChatBotInputDialog = ({ setMessage }) => {
  const [customerQuery, setCustomerQuery] = useState('');

  const raiseUserTicket = async () => {
    fetch(config.domain + "/create-ticket", {
      credentials: "include",
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        data: {
          query: customerQuery
        }
      }),
    }).catch(err => {
      console.log(err);
    })

    const messageData = {
      text: customerQuery,
      answer: "Our custom support will get back to you soon",
    }

    setMessage((prevState) => [...prevState, messageData])
  }

  return (
    <div className={`gap-4 w-full flex justify-center px-4 `}>
      <input
        type="text"
        placeholder="Ask your Questions..."
        onChange={e => setCustomerQuery(e.target.value)}
        className="rounded-full py-3 px-5 w-full border border-[#e1d3cb] focus:!outline-none focus:!border-2 focus:!border-[#3573fa] shadow-md"
      />
      <img onClick={raiseUserTicket} src={sendIcon} alt="Send" />
    </div>
  )
}

export default ChatBotInputDialog