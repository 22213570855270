import React, { useEffect, useState } from "react";
import { reviewsData } from "../../utils/data";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import Cards from "../Cards/Cards";

const Testimonials = () => {

  const [noOfCards, setNoOfCards] = useState(6);  


  useEffect(() => {
    const handleResize = () => {
      console.log(window.innerWidth);
      if (window.innerWidth <= 768) {
        setNoOfCards(3);
      } else if (window.innerWidth <= 1024) {
        setNoOfCards(4);
      } else {
        setNoOfCards(6);
      }
    };

    handleResize(); // Call once on initial render
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="flex flex-col px-20 sm:md-max:px-10 md-small:px-4 py-16 gap-7 justify-center items-center overflow-hidden" id="review">
      <h3 className="text-4xl font-medium md-small:text-center">
        This is Why Customers Love Us
      </h3>
      <p className="text-[#526277] text-base max-w-[50%] md-max:max-w-full text-center">
        Increase your customer base and enhance conversion rates by providing
        the most widely used payment methods worldwide
      </p>

      <div className="grid grid-cols-3 sm:md-max:grid-cols-2 md-small:grid-cols-1 gap-4">
        {reviewsData
          ?.slice(0, noOfCards)
          .map((review) => (
            <div className="col-span-1 rounded-xl border-2 border-[#DFE1E6] p-6 flex flex-col justify-between gap-5 hover:shadow-lg hover:shadow-blue-500/70">
              <p>"{review?.review}"</p>
              <div className="flex flex-col gap-2">
                <hr />
                <div className="flex items-center gap-4">
                  <img
                    src={review?.image}
                    alt={review?.alt}
                    className="rounded-full size-14"
                  />
                  <div className="flex flex-col">
                    <p className="text-base font-medium">{review?.name}</p>
                    <p className="text-sm text-[#666D80]">
                      {review?.designation}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* <div className="relative h-[500px]">
        <Cards />
      </div> */}
    </div>
  );
};

export default Testimonials;
