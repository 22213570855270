import React, { useState } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { FAQs } from "../../utils/data";

const FAQ = () => {
  // Manage the expanded state for each tab
  const [activeIndex, setActiveIndex] = useState(null);

  // Toggle the active state
  const toggleTab = (index) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div className="flex lg-small:flex-col justify-between mt-16 px-20  lg-small:gap-10 " >
      <div className="sm:lg-small:text-center sm-max:text-left" id="faq" >
        <h1 className="text-4xl font-medium">Frequently Asked Questions</h1>
      </div>
      <div className="w-1/2 lg-small:w-full">
        <Accordion
          activeIndex={activeIndex}
          onTabChange={(e) => setActiveIndex(e.index)}
          className="flex flex-col gap-4"
        >
          {FAQs.map((item, index) => (
            <AccordionTab
              key={index}
              className={`[&>a]:bg-white [&>a>svg]:hidden [&>a]:rounded-t-lg [&>a]:border-t [&>div]:rounded-b-lg [&>a]:border-x [&>div]:border-x [&>div]:border-b [&>*]:border-[#DFE1E6] [&>a]:py-4 [&>div]:pt-0 ${
                activeIndex !== index ? "[&>a]:rounded-b-lg [&>a]:border-b" : ""
              }`}
              header={
                <div className="flex items-center w-full ">
                  <span className="font-medium white-space-nowrap">
                    {item.question}
                  </span>
                  <button
                    className="text-lg font-bold ml-auto"
                    onClick={(e) => {
                      // e.stopPropagation();
                      toggleTab(index);
                    }}
                  >
                    {activeIndex === index ? "−" : "+"}
                  </button>
                </div>
              }
            >
              <p className="m-0">{item?.answer}</p>
            </AccordionTab>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FAQ;
