import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog'
import { useRecoilState } from "recoil";
import { languageSelectionModal, selectedLang } from "../../utils/recoil";
import { useNavigate } from "react-router-dom";

const LanguageSelection = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useRecoilState(languageSelectionModal);
  const [lang, setLang] = useRecoilState(selectedLang);
  const langs = {
    eng: { name: 'English', code: 'en' },
    hin: { name: 'Hindi', code: 'hi' },
    mal: { name: 'Malayalam', code: 'mal' },
    ar: { name: 'Arabic', code: 'ar' },
    ta: { name: 'Tamil', code: 'ta' },
  }
  const selectLanguage = (lang) => {
    setLang(langs[lang]);
    setVisible(false);
    navigate('/rough');
  }

  return (
    <Dialog visible={visible} onHide={() => setVisible(false)} className="w-[40%] md-max:w-[60%] sm-max:w-[80%] ">
      <p className="text-center text-2xl">Select Your Language</p>
      <div className="flex flex-col gap-3 p-5 !bg-transparent" style={{ background: "transparent" }}>
        <div className="flex justify-center">
          <button onClick={() => selectLanguage('mal')} className="border-[2px] border-[#1c75bc] rounded-md p-2 text-xl  text-black w-[30%] sm-max:w-[80%] hover:bg-[#1c75bc] hover:text-white duration-300">Malayalam</button>
        </div>
        <div className="flex justify-center">
          <button onClick={() => selectLanguage('eng')} className="border-[2px] border-[#1c75bc] rounded-md p-2 text-xl  text-black w-[30%] sm-max:w-[80%] hover:bg-[#1c75bc] hover:text-white duration-300">English</button>
        </div>
        <div className="flex justify-center">
          <button onClick={() => selectLanguage('hin')} className="border-[2px] border-[#1c75bc] rounded-md p-2 text-xl  text-black w-[30%] sm-max:w-[80%] hover:bg-[#1c75bc] hover:text-white duration-300">Hindi</button>
        </div>
        <div className="flex justify-center">
          <button onClick={() => selectLanguage('ar')} className="border-[2px] border-[#1c75bc] rounded-md p-2 text-xl  text-black w-[30%] sm-max:w-[80%] hover:bg-[#1c75bc] hover:text-white duration-300">Arabic</button>
        </div>
        <div className="flex justify-center">
          <button onClick={() => selectLanguage('ta')} className="border-[2px] border-[#1c75bc] rounded-md p-2 text-xl  text-black w-[30%] sm-max:w-[80%] hover:bg-[#1c75bc] hover:text-white duration-300">Tamil</button>
        </div>
      </div>
    </Dialog>
  )
}

export default LanguageSelection