import React from "react";
import "./Herosecond.css";
import person1 from "../../Assets/image 10 (1).svg";
import person2 from "../../Assets/DOCTOR.png";
import person3 from "../../Assets/CEO.png";
import person4 from "../../Assets/MECHANIC.png";
import person5 from "../../Assets/SCIENTIST.png";
import newlogo from "../../Assets/Isolation_Mode.svg";
import frame1 from "../../Assets/hi-welcome-meeting-shot-young-businessman-using-his-laptop-video-call 1.svg";
import frame2 from "../../Assets/image 10 (1).svg";
import newprofile from "../../Assets/Frame 579.png";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import logo from "../../Assets/power.svg";
import images from "../../Assets/Frame 1000004370.svg";
import images1 from "../../Assets/Frame 1000004371.svg";
import smallbox from "../../Assets/DOCTOR.png";
import smallbox1 from '../../Assets/character_img.svg'
import smallbox2 from '../../Assets/character_img2.svg'
import grand from "../../Assets/SCIENTIST.png";
import profile5 from "../../Assets/image 4.svg";
import boy from "../../Assets/1.svg";
import girl from "../../Assets/2.svg";
import Reviews from "../Reviws/Reviews";
import iframe_img from '../../Assets/iframe_Img.png'
import Footer from "../Homepagefooter/Footer";
import { useNavigate } from "react-router-dom";
import FAQ from "../FAQ/FAQ";
import Testimonials from "../Testimonials/Testimonials";
import TestCard from "../TestCard/TestCard";

const Herosecond = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="hero-secon-main">
        <div className="hero-second-container">
          <div className="scen-main">
            <div className="hero-images-main">
              <div className="hero-image">
                <div className="hero-single">
                  <img src={person1} alt="" />
                </div>
                <div className="hero-single-2">
                  <img src={person2} alt="" className="w-28 h-28 sm-max:w-20 sm-max:h-12" />
                </div>
              </div>
              <h1 className="great">Discover Your Perfect Career Path with MyAptitude.ai</h1>
              <div className="second-image-section-with-heading">
                <div className="hero-single-second">
                  <img src={person4} alt="" className="w-28 h-28 sm-max:w-20 sm-max:h-12"/>
                </div>

                <div className="hero-single-second-2">
                  <img src={person3} alt="" className="w-28 h-28 sm-max:w-20 sm-max:h-12"/>
                </div>
              </div>
            </div>
            <div className="hero-second-last-image">
              <img src={person5} alt="" className="w-28 h-28 sm-max:w-20 sm-max:h-12"/>
            </div>
          </div>

          <TestCard />

          {/* <div className="hero-section-blue-box">
            <div className="herosecond-content-section">
              <img src={newlogo} alt="" />
              <h1>
                Answer me <br /> questions to know your personality
              </h1>
              <p>Subscribe to our newsletter to get the latest Metarik news</p>
              <div>
                <button  onClick={() => navigate('/home')}>Start the test</button>
              </div>
            </div>
            <div className="herosecond-image-section">
              <div className="girl-iamge-1">
                <img src={frame1} alt="" />
                <div className="feedback">
                  <img
                    className="feedimage"
                    style={{ width: "50px", height: "50px" }}
                    src={newprofile}
                    alt=""
                  />
                  <div>
                    <h1>Mathew Anderson</h1>
                    <p>Defender : ISFJ-A </p>
                  </div>
                </div>
              </div>

              <div className="girl-iamge-2">
                <img src={frame1} alt="" />
                <div className="feedback1">
                  <img
                    className="feedimage"
                    style={{ width: "50px", height: "50px" }}
                    src={newprofile}
                    alt=""
                  />
                  <div>
                    <h1>Mathew Anderson</h1>
                    <p>Defender : ISFJ-A </p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <div className="hero-thrid-section-container">
        <div className="hero-third-section-abouts">
          <div className="third-section-heading">
            <h1>Utilize Prepifyer to gain unparalleled insight</h1>
          </div>
          <div className="third-section-para" id="features">
            <p>
              Discover the depths of your being, Before you seek knowledge from
              without. Know your strengths, your flaws, your dreams, Then
              educate, empowered from within, without a doubt.
            </p>
            <div className="test-button">
              <button onClick={() => navigate('/home')}>
                Take the Test Now <HiOutlineArrowSmRight />
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="hero-fourth-section">
        <div className="coloumns-main">
          <div className="mobile-view">
            <div className="first-coloumn">
              <div className="fisrt-box">
                <div className="i-frame-box">
                  <h1>
                    Find <br />
                    Your Potential
                  </h1>

                  <div className="frame">
                    {/* <iframe
                      title="Embedded Page"
                      src="../../revies"
                      width="100%"
                      height="100%"
                      style={{ borderRadius: "10px 0px 0px 0px" }}
                    /> */}
                    <img src={iframe_img} alt="img" className="w-[100%] h-[100%]" />
                  </div>
                </div>
              </div>

              <div className="second-box">
                <h1>
                  1300 +<br /> Users
                </h1>
                <p>Helped over 1300 students to reach their potential</p>
              </div>
            </div>
            <div className="second-coloumn">
              <div className="third-box">
                <img src={logo} alt="" />
                <h1>
                  AI <br />
                  Powered
                </h1>
              </div>
              <div className="fourth-box">
                <div className="forth-box-image-1">
                  <img src={grand} alt="" className="w-32 h-48 sm-max:w-22 sm-max:h-24"/>
                  <div className="forth-absolute">
                    <img src={profile5} alt="" />
                    <div className="forth-feed">
                      <h2>Mathew Anderson</h2>
                      <p>Defender : ISFJ-A </p>
                    </div>
                  </div>
                </div>
                <div className="forth-box-image-2">
                  <img src={boy} alt="" />
                  <div className="forth-absolute-2">
                    <img src={profile5} alt="" />
                    <div className="forth-feed-2">
                      <h2>Mathew Anderson</h2>
                      <p>Defender : ISFJ-A </p>
                    </div>
                  </div>
                </div>
                <div className="forth-box-image-3">
                  <img src={girl} alt="" />
                  <div className="forth-absolute-3">
                    <img src={profile5} alt="" />
                    <div className="forth-feed-3">
                      <h2>Mathew Anderson</h2>
                      <p>Defender : ISFJ-A </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="third-coloumn">
            <div className="large-box">
              <h1>
                Your path to success more <br />
                clearer than ever
              </h1>
            </div>
            <div className="last-two">
              <div className="small-box-1">
                <div className="small-b1">
                  <div className="small-b2-images">
                    <img src={smallbox} alt="" className="rounded-full" />
                    <img className="slop" src={smallbox1} alt="" />
                  </div>
                  <img src={smallbox2} alt="" />
                  <h1 className="hea">
                    16 <span>x6</span>{" "}
                  </h1>
                  <h1 className="headd">Personality TraitsTo Discover</h1>
                </div>
              </div>
              <div className="small-box-2">
                <div className="small-b2">
                  <h1>World Wide Users</h1>

                  <img src={images} alt="" />
                  <img src={images1} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {/* <Reviews /> */}
        <Testimonials />
        <FAQ />
      </div>
      <div className="footer-hero">
        <Footer />
      </div>
    </>
  );
};

export default Herosecond;
