import React from 'react'
import { ColorRing } from 'react-loader-spinner'

export const LoadingPage = () => {
    return (
        <div className='h-full w-full absolute bg-white flex justify-center items-center'>
            <div>
                <ColorRing
                    visible={true}
                    height="80"
                    width="80"
                    ariaLabel="color-ring-loading"
                    wrapperStyle={{}}
                    wrapperClass="color-ring-wrapper"
                    colors={['#F0F8FF', '#384C9D', '#56A0D3', '#6AB9E3', '#7ED2F3']}
                />
            </div>
        </div>
    )
}
