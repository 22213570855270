import React, { useState, useEffect, useRef } from "react";
import { Dialog } from "primereact/dialog";
import { CiClock2 } from "react-icons/ci";
import { FaUserCircle } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import config from '../../config.json';
import { Toast } from "primereact/toast";
import moment from "moment";
import { resultSelector, selectedLang } from "../../utils/recoil";
import { useRecoilState, useRecoilValue } from "recoil";
import { languagesCode } from "../../utils/data";

const CommentSection = () => {
  const [totalComments, setTotalComments] = useState(0);
  const toast = useRef(null);
  const [pageSize, setPageSize] = useState({
    start: 0,
    end: 6
  });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [isEditing, setIsEditing] = useState(false); // Track editing mode
  const [comments, setComments] = useState([]);
  const _testResult = useRecoilValue(resultSelector);
  const [lang, setLang] = useRecoilState(selectedLang);
  const tokenCookie = Cookies.get('token', { domain: config.cookieDomain });
  const isPaidCookie = Cookies.get('role', { domain: config.cookieDomain });
  const isTestTaken = Cookies.get('isTestAttended', { domain: config.cookieDomain });
  const usernameCookie = Cookies.get('username', { domain: config.cookieDomain });
  const userEmail = Cookies.get('email', { domain: config.cookieDomain });
  const navigate = useNavigate();
  const location = useLocation();
  console.log("_testResult", _testResult);
  console.log("comments", comments);
  useEffect(() => {
    // console.log(isPaidCookie, tokenCookie);
    if (tokenCookie) {
      if (isPaidCookie && (isPaidCookie === 'ROLE_UNPAID_USER')) navigate('/home');
      if (isTestTaken && (isTestTaken === 'FALSE')) navigate('/home');
    } else {
      window.location.href = config.domain + '/login';
    }
  }, [])

  useEffect(() => {
    if (_testResult?.id) {
      fetch(config.domain + `/comments?result_id=${_testResult.id}&start=${pageSize?.start}&end=${pageSize?.end}`, {
        credentials: "include",
      }).then(res => res.json())
        .then(result => {
          if (!totalComments) setTotalComments(result?.data.totalComments)
          setComments([...comments, ...result?.data.comments])
        })
    } else {
      setComments([])
    }
  }, [_testResult, pageSize])

  const openModal = (commentObj = {}) => {
    setIsEditing(commentObj?.id ? commentObj?.id : ''); // Determine if we're editing or adding a new comment
    setComment(commentObj?.comment ? commentObj?.comment : '');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setComment("");
    setIsEditing(false);
  };

  const handleSubmit = () => {
    if (_testResult) {
      if (isEditing) {
        // Update existing comment

        try {
          // Send Razorpay payment ID and order ID to your server for verification
          fetch(config.domain + "/comment", {
            credentials: "include",
            method: "PUT",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                id: isEditing,
                comment: comment
              }
            })
          }).then(res => res.json())
            .then(res => {
              if (res.statusCode === 200) {
                setComments((prevComments) =>
                  prevComments.map((c) =>
                    c.id === isEditing ? { ...c, comment: comment, updatedAt: res.data.updatedAt } : c
                  )
                );
                toast.current.show({
                  severity: "success",
                  summary: "Comment updated successfully",
                  life: 3000,
                });

              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Failed to update comment",
                  life: 3000,
                });
              }
            })
            .catch(err => {
              toast.current.show({
                severity: "error",
                summary: "Failed to update comment",
                life: 3000,
              });
            })
        } catch (err) {
          toast.current.show({
            severity: "error",
            summary: "Failed to update comment",
            life: 3000,
          });
        }
      } else {
        // Add a new comment
        try {
          // Send Razorpay payment ID and order ID to your server for verification
          fetch(config.domain + "/comment", {
            credentials: "include",
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {
                resultId: _testResult.id,
                comment: comment
              }
            })
          }).then(res => res.json())
            .then(res => {
              if (res.statusCode === 200) {
                if (res.statusCode === 200) {
                  if (res?.error?.msg === 'comment.limit.exceeded') {
                    toast.current.show({
                      severity: "error",
                      summary: "Your Comment Limit Has Been Exceeded",
                      life: 3000,
                    });
                  } else {
                    setComments([
                      ...comments,
                      res.data
                    ]);
                    toast.current.show({
                      severity: "success",
                      summary: "Comment added successfully",
                      life: 3000,
                    });
                  }
                }

              } else {
                toast.current.show({
                  severity: "error",
                  summary: "Failed to add comment",
                  life: 3000,
                });
              }
            })
            .catch(err => {
              toast.current.show({
                severity: "error",
                summary: "Failed to add comment",
                life: 3000,
              });
            })
        } catch (err) {
          toast.current.show({
            severity: "error",
            summary: "Failed to add comment",
            life: 3000,
          });
        }
      }
      closeModal();
    }
  };

  return (
    <div className="mt-16 px-20 flex flex-col md-small:px-4">
      <Toast ref={toast} />
      {/* Header */}
      <div className="mb-10">
        <div className="flex justify-between">
          <h4 className="text-[#343c4b] text-4xl md-small:text-xl">Comments</h4>
          <button
            className="px-4 py-2 bg-[#1C75BC] rounded-lg text-white font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 md-small:text-sm"
            onClick={() => openModal()}
          >
            Add Comment
          </button>
        </div>
      </div>

      {/* Comment Section */}
      {comments?.length ? comments.map((c) => (
        <div key={c?.id} className="p-5 flex justify-between border-b border-t md-small:p-0 md-small:py-3">
          <div className="flex gap-3">
            {c.user?.picture ?
              <img src={c?.user?.picture} alt="profile-img" style={{ borderRadius: '50%', height: "47px", width: '47px' }} />
              :
              <FaUserCircle size={38} color="#1C75BC" />
            }
            <div className="flex flex-col">
              <p className="text-[#1c36bc] text-lg font-semibold">{c?.user?.name}</p>

              <span className="flex gap-3 ">
                {c?.comment}
                {c?.user?.email === userEmail && < MdEdit
                  className="cursor-pointer"
                  onClick={() => openModal(c)}
                />}
              </span>

            </div>
          </div>
          <div className="flex items-start gap-2">
            <CiClock2 color="#787f8d" className="mt-[2px]" />
            <p className="text-sm text-[#787f8d] md-small:min-w-max">{moment(new Date(c?.updatedAt))?.fromNow()}</p>
          </div>
        </div>
      )) :
        <p className="flex items-center py-4">0 comments </p>
      }

      {/* Paginator */}
      {
        (comments?.length < totalComments) && (comments?.length !== 0) &&
        <div className="flex justify-center items-center py-4">
          <div onClick={() => setPageSize({
            start: pageSize.start + 6,
            end: pageSize.end + 6
          })} className="py-2 px-14 bg-[#1C75BC] text-white rounded-lg cursor-pointer">
            Load More
          </div>
        </div>
      }

      {/* Add/Edit Comment Modal */}
      <Dialog
        header={isEditing ? "Edit Comment" : "Add Comment"}
        visible={isModalOpen}
        onHide={closeModal}
        className="w-[70%] md-small:w-[90%]"
      >
        <div className="p-4">
          <textarea
            className="w-full p-2 border rounded-lg"
            rows="4"
            placeholder="Write your comment here..."
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
          <div className="mt-4 flex justify-end gap-4">
            <button
              className="px-4 py-2 bg-gray-300 rounded-lg hover:bg-gray-400"
              onClick={closeModal}
            >
              Cancel
            </button>
            <button
              className="px-4 py-2 bg-[#1C75BC] text-white rounded-lg hover:bg-blue-700"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default CommentSection;
