import React, { useEffect, useRef, useState } from "react";
import "./Quizhome.css";
import power from "../../Assets/Group 1.svg";
import { IoCloseOutline } from "react-icons/io5";
import { IoMdArrowForward } from "react-icons/io";
import usloticon from "../../Assets/my-aptitude-ai-uslot.png";
import Cookies from "js-cookie";
import useRazorpay from "react-razorpay";
import { useNavigate, Link } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import { couponCode, courseAmount, isPaid, redeemedAmount } from "../../utils/recoil";
import config from "../../config.json";
import { Toast } from "primereact/toast";
import logo from "../../Assets/my-aptitude-ai-uslot.png";
import { GoDotFill } from "react-icons/go";
import LanguageSelection from "../QuizInstruction/LanguageSelection";
import ApplyCode from '../ApplyCode/ApplyCode';
import { LoadingPage } from "../LoadingPage/LoadingPage";

const Quizhome = () => {
  const navigate = useNavigate();
  const [_isPaid, setIsPaid] = useRecoilState(isPaid);
  const toast = useRef(null);
  const amount = useRecoilValue(redeemedAmount);
  const _couponCode = useRecoilValue(couponCode);
  const [loading, setLoading] = useState(false)
  const isPaidCookie = Cookies.get("role", { domain: config.cookieDomain });

  useEffect(() => {
    const orderId = JSON.parse(localStorage.getItem('orderId'));
    if (orderId) {
      if (isPaidCookie === "ROLE_UNPAID_USER") {
        fetch(config.domain + "/check-payment", {
          credentials: "include",
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            orderId,
            paymentId: null,
            couponCode: _couponCode
          })
        }).then(res => res.json())
          .then(res => console.log(res))
          .catch(err => console.log(err))
      } else {
        localStorage.removeItem("orderId");
      }
    }
  }, [])

  const preventRefresh = (e) => {
    e.preventDefault();
    alert("Don't refresh the window until the payment is complete.");
  };

  useEffect(() => {

    return () => {
      window.removeEventListener('beforeunload', preventRefresh);
    }
  }, [])

  const createOrder = async () => {
    return fetch(config.domain + "/payment/" + amount * 100, {
      method: "GET",
      credentials: "include",
    })
      .then((res) => res.json())
      .then((res) => res.orderId)
      .catch((err) => console.log(err));
  };
  const [Razorpay] = useRazorpay();

  const checkIsLogged = async () => {
    setLoading(true);
    window.addEventListener('beforeunload', preventRefresh);
    const cookieValue = Cookies.get("token", { domain: config.cookieDomain });
    const isTestTaken = Cookies.get("isTestAttended", {
      domain: config.cookieDomain,
    });
    const usernameCookie = Cookies.get("username", {
      domain: config.cookieDomain,
    });

    if (cookieValue) {
      if (isPaidCookie === "ROLE_UNPAID_USER") {
        toast.current.show({
          severity: "warn",
          summary: "Don't close the window until the payment is complete.",
          life: 10000,
        });

        const order = await createOrder();
        localStorage.setItem('orderId', JSON.stringify(order));

        const options = {
          key: "rzp_live_sZYDjcKroJIv8E",
          amount: amount * 100,
          currency: "INR",
          name: "Uslot Edu",
          description: "Uslot Career Test",
          image: logo,
          order_id: order,
          handler: function (response) {
            try {
              // Send Razorpay payment ID and order ID to your server for verification
              fetch(config.domain + "/verify-payment", {
                credentials: "include",
                method: "POST",
                headers: {
                  Accept: "application/json",
                  "Content-Type": "application/json",
                },
                body: JSON.stringify({
                  paymentId: response.razorpay_payment_id,
                  orderId: response.razorpay_order_id
                    ? response.razorpay_order_id
                    : order,
                  couponCode: _couponCode
                }),
              })
                .then((res) => res.json())
                .then((res) => {
                  if (res.data) {
                    // Payment successful, update UI or show success message
                    console.log("Payment successful:");
                    toast.current.show({
                      severity: "success",
                      summary: "Payment Successful",
                      life: 3000,
                    });
                    setIsPaid(true);
                    setLoading(false);
                    localStorage.removeItem("orderId");
                    window.removeEventListener('beforeunload', preventRefresh);
                    navigate('/instruction');
                  } else {
                    // Payment verification failed, handle accordingly
                    console.error("Payment verification failed:");
                    setLoading(false);
                    window.removeEventListener('beforeunload', preventRefresh);
                    toast.current.show({
                      severity: "error",
                      summary: "Payment Failed",
                      life: 3000,
                    });
                  }
                })
                .catch((err) => {
                  console.log(err);
                  setLoading(false);
                  window.removeEventListener('beforeunload', preventRefresh);
                  toast.current.show({
                    severity: "error",
                    summary: "Payment Failed",
                    life: 3000,
                  });
                });
            } catch (error) {
              console.error("Error verifying payment:", error);
              window.removeEventListener('beforeunload', preventRefresh);
              toast.current.show({
                severity: "error",
                summary: "Payment Failed",
                life: 3000,
              });
              setLoading(false)
            }
          },
          prefill: {
            name: usernameCookie,
          },
          theme: {
            color: "#3399cc",
          },
        };

        const rzp1 = new Razorpay(options);

        rzp1.on("payment.failed", function (response) {
          console.log(response);
          setLoading(false)

        });

        rzp1.open();
      } else {
        setLoading(false)
        window.removeEventListener('beforeunload', preventRefresh);
        if (isTestTaken && (isTestTaken === 'TRUE')) navigate('/detail_result_page');
        navigate('/instruction');
      }
    } else {
      window.removeEventListener('beforeunload', preventRefresh);
      localStorage.setItem('redirectTo', JSON.stringify('home'));
      setLoading(false)
      navigate("/login");
    }
    setLoading(false);
  };

  return (
    <div className="quizhero-main">
      <Toast ref={toast} />
      {loading ? (
        <LoadingPage />
      ) : (
        <>
          <div
            className="hero-content-section"
          >
            <div className="hero-contents">
              {/* <div className="close-button">
            <btutton className="close-btn">
              <IoCloseOutline style={{ fontSize: "30px" }} />
              close
            </btutton>
          </div>{" "} */}
              <div className="power">
                <img src={power} alt="" />
                <h2>Powered by AI</h2>
              </div>
              <h1>
                Unlock your <span className="heding-span">career </span>
                <br />
                potential <span className="heding-span">today! </span>
              </h1>
              <div className=" flex flex-col gap-1 w-full">
                <p>Discover your future path with our accurate career prediction tests, Before you start, here are some quick instructions:</p>
                <div className="flex gap-2 flex-col p-2 sm-max:gap-1 sm-max:p-1">
                  <p className="flex  gap-1"><span className="mt-2"><GoDotFill size={16} /></span>Choose a quiet place where you can focus without interruptions.</p>
                  <p className="flex  gap-1"><span className="mt-2"><GoDotFill size={16} /></span>Answer each question honestly. There are no right or wrong answers.</p>
                </div>
                <p><a className="text-blue-500 underline" href="mailto:info@uslotedu.com">Need Help?</a> If you're stuck or have questions, we're here to help.</p>
              </div>
            </div>
            <div className="lets-start-button flex flex-col gap-6 w-full">
              <div className="limited-offer-container flex bg-white  rounded-lg px-6 py-6 items-center justify-between sm-max:px-4 sm-max:gap-4 sm-max:py-4">
                <div className="flex flex-col">

                  <p className="text-xl font-bold">Limited Time Offer</p>
                  <ApplyCode toast={toast} />
                </div>
                <div>
                  <p className="opacity-70 line-through decoration-red-700">₹1999</p>
                  <p className="text-[27px] font-bold">₹{amount}/Test</p>
                </div>
              </div>
              <div onClick={checkIsLogged} className="w-full">
                <button className="start-btn w-full ">
                  Take Test Now
                </button>
              </div>
            </div>
          </div>
          <div className="hero-image-section">
            <Link to={"/"}>
              <img className="u-icon" src={usloticon} alt="icon" />
            </Link>
          </div>
        </>)}
    </div>
  );
};

export default Quizhome;
